import logoFooter from './assets/vectors/blue.svg';
import logo from './assets/vectors/square.svg';
import './assets/css/index.scss';
import copy from 'copy-to-clipboard';

import 'bootstrap/dist/css/bootstrap.min.css';

import { OverlayTrigger, Container, Row, Col } from "react-bootstrap";

function App() {

  const onClick = event => {
    // const {
    //   children,
    //   options
    // } = this.props;

    // event.preventDefault();
    // copy(children, options);
  };
  return (
    <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
      <header className="App-header">
        <div class="background"></div>
        <div class="disclaimer">
          <span>Invadis website is coming soon.<br/> Meanwhile you can contact us via</span> <a onClick={onClick}>info@invadis.com</a> <span>or</span> <em>+7 (495) 149-94-00</em> <span>and we’ll be pleased to help you.</span>
        </div>
      </header>
      <footer>

      <Container fluid={"md"}>
      <Row>
      <Col sm={8}>

      <img src={logoFooter} className="logo-footer" alt="logo" />
      <div className="address">77a Osharskaya st., office 108, room 23<br/>
Nizhny Novgorod, Russia, 603105</div>
      <div className="hotline">Customer support
      <span>+7 (495) 149-94-00</span></div>

</Col>
      <Col sm={4}  className="copyright-wrapper"><div className="copyright">
      © 2021 Invadis. All Rights Reserved.</div>
</Col>

</Row>
</Container>
      </footer>
    </div>
  );
}

export default App;
